import React from 'react'
// import './Header2.css';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AuthUser from '../../AuthUser/AuthUser';
import WelScreen from '../../Pages/Welcome Screen/WelScreen';

const Header4 = () => {
    const {getToken} = AuthUser();
   
    return (

        <div className='Main-Header2-Container'>

            <div className='Header-2-div'>


                <Link to="/Home" className='Backward-Link'>

                    <FaArrowLeft className="BackWard-Btn" />
                </Link>

                <p className='Header-2-Text'>My Prediction History</p>

            </div>

        </div>
    )
}

export default Header4;