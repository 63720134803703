import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { getAuth, signOut } from "firebase/auth";
import Fade from 'react-reveal/Fade'; // Importing Fade effect;
export default function AuthUser() {
    const auth = getAuth();
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
       
        signOut(auth).then(() => {
        
            navigate('/Login');
        }).catch((error) => {
            // An error happened.
        });
     
    }
    const saveToken = (user, token) => {
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('user', JSON.stringify(user));

        setToken(token);
        setUser(user);
        navigate('/Home')
    }
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const getUser = () => {
        const UserString = localStorage.getItem('user');
        const user_detail = JSON.parse(UserString);
        return user_detail;
    }
    const alertMessage = (status, message) => {
        return setToken ?
            <Fade>
            <Stack sx={{ width: '90%', margin: '5px auto' }} spacing={2}>
                <Alert severity={status}>{message}</Alert>
            </Stack>
            </Fade>
            : <Fade><Stack sx={{ width: '90%', margin: '5px auto' }} spacing={2}>
                <Alert severity={status}>{message}</Alert>
            </Stack></Fade>;
    };

    const [token, setToken] = useState(getToken);
    const [user, setUser] = useState(getUser);

    const http = axios.create({
        baseURL: 'https://admin.3prediction.com/api/',
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return {
        alertMessage,
        setToken: saveToken,
        user,
        token,
        getToken,
        logout,
        http
    }
}