import React from 'react';
import './winner.css';
import winner from '../../../Video/winner.mp4';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import Fade from 'react-reveal/Fade'; // Importing Fade effect

const Winner = () => {
    return (
        <Fade>
        <div className='Main-Winner-Container'>

            <div className='BackWard-Btn-div'>
                <Link to="/Home" className='Icon-Btn'><BsArrowLeft className='Back-icon' /> </Link>
            </div>


            <div className='Con-text-div'>


                <div>

                    <video muted loop autoPlay={true} playsInline>
                        <source src={winner} className="Winner-Video" />
                    </video>

                </div>

                <div className='cong-text'>

                    <p className='Name-Winner'>Mustafa Ghouri</p>
                    <p className='Name-Winner-1'>Congratulations, You are a Winner.</p>

                </div>

            </div>

            <div className='Winner-Table'>
                <table width="100%" className='Tab-Div'>

                    <tr >
                        <td className='Table-heading'><b>Other Winner</b></td>
                    </tr>
                    <tr>
                        <td className='Table-heading-1'>Wajhi</td>
                    </tr>
                    <tr>
                        <td className='Table-heading-2'>Wahaj</td>
                    </tr>
                    <tr>
                        <td className='Table-heading-1'>Sami</td>
                    </tr>
                    <tr>
                        <td className='Table-heading-2'>Usman</td>
                    </tr>
                    <tr>
                        <td className='Table-heading-1'>Hassanin</td>
                    </tr>
                </table>
            </div>


        </div >
        </Fade>
    )
}

export default Winner;