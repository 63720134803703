import * as FormData from 'form-data';
import React, { useState } from 'react';
import '../Login/Login.css'; 
import { Link } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../AuthUser/AuthUser'; 
import Fade from 'react-reveal/Fade'; // Importing Fade effect 

const ForgotPassword = () => {

    const navigate = useNavigate()
    const { getToken } = AuthUser();
    if (getToken()) {
        navigate('/Home')
    }
  
    const { http, alertMessage } = AuthUser();
    const [email, setEmail] = useState("");  
    const [msg, setMsg] = useState(null);
    async function submitForm(e) {
        e.preventDefault();
        if(email !=""){
        let data = new FormData()
        data.append('email', email)
        data.append('token', 'C8xWxGvIue37SwP2MEU7W5oKE32fm7Z3JxHfeK897a8eE0SdLl')
        http.post('forgot', data)
            .then(function (res) { 
                if (res.data.data.status == "success") {
                    setTimeout(() => {
                        navigate('/Login') 
                    }, 3000);
                }
                setMsg(null);
                setMsg(alertMessage(res.data.data.status, res.data.data.msg));
            })
            .catch(err => console.log(err))
        } else{
            const status = "warning";
            const msg = "Enter Your Email";
            setMsg(null);
            setMsg(alertMessage(status,msg));
        }
    }

    // const  [googleprovider, facebookprovider, appleprovider ] = authMethod()
    

   

    return (
        <Fade>
        <div className='Main-Login-container'>
        <div className="forgotHeader">
                <div className="backtologin">
                <Link to='/Login' className='sign-up-btn'> <i className='fa fa-chevron-left'></i></Link>
                </div>
                <p>Back to Login</p>
            </div>
            <div className=' Main-Heading-Div'>
                <p className='SignUp-heading' onClick={()=>{navigate('/')}}>Top3 Prediction</p>
                <p className='create-heading'>Forgot Password</p>
            </div>
            
            <div id="alert" className='p-3'>
                {msg ? msg : ""}
            </div>
            <form action="" onSubmit={submitForm} method="post">
                <div className='EP-Div'>
                    <div className='E-Div'>
                        <label>Email Address</label>
                        <input type="text" name='email' placeholder='youremail@yourdomain' onChange={e => setEmail(e.target.value)} />
                    </div> 
                </div>
                <div className='LWE-Div'>
                    <button className='Link-LWH' type="submit">Send</button>
                </div>
                
            </form>
        </div>
        </Fade>
    )
}
export default ForgotPassword