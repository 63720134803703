import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {HiQuestionMarkCircle } from 'react-icons/hi';
import './Tooltip.css'

export default function TriggersTooltips() {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <div className='Tool-tip-Btn'>
            <Grid container justifyContent="center">
                <Grid item>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div >
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                               
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Current Prize is based of Total registered users."
                                arrow={true}
                            >
                                <Button onClick={handleTooltipOpen} className="prize_tool_tip_btn"><HiQuestionMarkCircle size={"25px"} color={"rgb(227 230 233)"} /></Button>
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                </Grid>
            </Grid>
        </div>
    );
}
