import React from 'react';
import './Header.css';
import MyDrawer from '../Drawer/Drawer';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Bell from '../../../Image/bell.svg'
import Notification from '../Notification/Notification'
import AuthUser from '../../AuthUser/AuthUser';
import WelScreen from '../../Pages/Welcome Screen/WelScreen';
import logo from '../../../Image/logo192.png'
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const Header = () => {
    const { getToken ,token} = AuthUser();
   
    return (

        <div className='Main-Header-Container'>

            <div className='Header-div-Main'>

            <div className='Header-Div-1 w-23'>
                    {token ? <MyDrawer /> : null}
                </div>

                <div className='Header-Div-2'>

                    {/* <p className='Heading-div-header'>Top3 Prediction</p> */}

                    <Link to='/Home'>

                        <img src={logo} alt="" className='logo-img' />

                    </Link>
                </div>
                {token ? (
                        <div className='Header-Div-3'>
                        <Notification />  
                </div>
                    ):(
                   
                        <Link className="login_btn" to="/Login">Login </Link>


                    ) }  

            </div>
        </div>

    )
}

export default Header;