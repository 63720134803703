import React, { Component, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AuthUser from '../../AuthUser/AuthUser';
import { Fade } from 'react-reveal';
import './idModal.css';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    margin: '0 auto',
    padding: '20px 10px',
};


const IdModal = () => {
    const { user, setToken, alertMessage, http } = AuthUser();
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = useState(null);

    useEffect(() => {
        setOpen(true)
    }, [])

    const [idNumer, setIdnumer] = useState('');
    const handleSubmit = () => {
        var countId = parseInt(idNumer.length); 
        if (idNumer != "" && countId == 6) {
            let data = new FormData()
            data.append('idNumer', idNumer)
            data.append('userid', user.id)
            http.post('registerIdnumber', data)
                .then(function (result) {
                    if (result.data.status == "success") {
                        setTimeout(() => {
                            setOpen(false);
                            setToken(result.data.user, result.data.access_token)
                        }, 1500);
                    }
                    setMsg(null);
                    setMsg(alertMessage(result.data.status, result.data.msg));
                })
                .catch(err => console.log(err))
        } else {
            setMsg(null);
            setMsg(alertMessage("warning", "Enter Correct Id Number !"));
        }

    }
    return (
        <div>
            <Fade>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="hasdjs31"
                >
                    <Box sx={style} >
                        <Typography id="modal-modal-title" className='text-center' variant="h6" component="h2">
                            Register Your Nationality Id
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div id="alert" className='p-3'>
                                {msg ? msg : ""}
                            </div>
                            <div className='Nick-div'>
                                <input type="text" maxLength="6" placeholder="Last 6 Digit Number (018292)" onChange={(e) => setIdnumer(e.target.value)} />
                                <i className='id_warning text-center'>warning:  Verification  needed for any withdrawal</i>

                            </div>
                            <div className='CA-Btn-div'>
                                <button className='CA-Btn' type="button" onClick={handleSubmit}>Submit</button>

                            </div>
                        </Typography>
                    </Box>
                </Modal>
            </Fade>
        </div>
    );

}
export default IdModal;