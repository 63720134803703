import React from 'react';
import './Thankyou.css'
import Loti from '../../../Video/Animation.mp4'
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade'; // Importing Fade effect
import Thank from '../../../Video/animation.gif'

const Thankyou = () => {

  return (
    <Fade>
    <div className='Main-Thank-Container'>
      {/* <video muted loop playsInline autoPlay={true} className="ThankYou-Loti">
        <source src={Loti} />
      </video> */}

      <img src={Thank} className="ThankYou-Loti" />
      <div className='Cong-Text-div'>

        <p className='Text-Congrat'>Congratulations</p>
        <p className='Make-Text'>You made a prediction!</p>

        <Link to='/Home' className='BTH-btn'>Back To Home</Link>
        <Link to='/SubmitPredict' className='BTH-btn'>Submit More</Link>

      </div>
    </div>
    </Fade>
  )
}

export default Thankyou