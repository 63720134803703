import React, { Component } from 'react'
import './adds.css';
import add1 from '../../../../src/Image/addBanner.jpg'; 
import Add from '../../../../src/Image/add.png';
export default class Adds extends Component {
  render() {
    return (
        <div className='Add-div'>
            <img src={Add} alt="img" className='Add-img' />
            <img src={add1} alt="img" className='Add-img' />
        </div>
    )
  }
}
