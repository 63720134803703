import React from "react";
import Advertisement from "./Advertisement";
import "./Advertisements.css";
import { advertisementsData } from "./data";

const Advertisements = () => {
  return (
    <div className="advertisements">
      <p className="advertiseHeader">Sponsor</p>
      {advertisementsData?.map((each, i) => (
        <Advertisement key={i} logo={each.img} />
      ))}
    </div>
  );
};

export default Advertisements;
