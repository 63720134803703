import React from "react";

const Advertisement = ({ logo, link, isPrimary }) => {
    const betNowClickHandler = () => {
    window.open("http://bit.ly/3V2cqI0");
  };
  return (
    <div onClick={betNowClickHandler} className="advertisement">
      <img src={logo} className="adv_logo" alt="" />
    </div>
  );
};

export default Advertisement;
