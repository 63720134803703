import logo from '../../../Image/logos/logo.jpg'
import logo1 from '../../../Image/logos/logo1.jpg'
import logo2 from '../../../Image/logos/logo2.jpg'
import logo3 from '../../../Image/logos/logo3.jpg'
import logo4 from '../../../Image/logos/logo4.jpg'

export const advertisementsData = [
    {
        img: logo,

    },
    {
        img: logo1,

    },
    {
        img: logo2,

    },
    {
        img: logo3,

    },
    {
        img: logo4,

    },
];