import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Bell from '../../../Image/bell.svg';
import './Notification.css';
import { BiUser } from 'react-icons/bi';
import { BiHappy } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5'
import { useState } from 'react';
import AuthUser from '../../AuthUser/AuthUser';

export default function Notification() {

    const { http, user } = AuthUser();
    const [setNotification, getData] = useState([]);

    const GetNotification = () => {
        let data = new FormData()
        data.append('user_id', user.id)

        http.post('notification', data)
            .then(response => {
                getData(response.data); 

            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        GetNotification()
    }, [])




    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <div className='Main-Noti-Container'>
                    {/* <p className='Today-Text'>Today</p> */}
                    {/* <p className='Today-Text'>Yesterday</p> */}
                    {setNotification.map((items, i) => (
                        <div className='Noti-Div' key={i}>
                            <div>
                            {items.message_type == 'champion'?<BiUser className="User-Icon-2" />:<BiHappy className="Happy-Icon-2" />}
                            {/* {items.message.message_type == 'referal'?<BiHappy className="Happy-Icon-2" />:} */}
                          
                            </div>
                            <div>
                                <p className='Noti-Text'>{items.message}</p>
                            </div>

                        </div>
                    ))}
                </div>
            </List>
            <button onClose={toggleDrawer(anchor, false)} className='close-Btn'>Close <IoCloseOutline className='Close-Icon' /></button>
        </Box>
    );
    return (
        <div>
            {['top'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button className="Noti-Btn" onClick={toggleDrawer(anchor, true)}>{<img src={Bell} alt='img' />}</Button>
                    <Drawer
                        transitionDuration={{ enter: 1000, exit: 800 }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
