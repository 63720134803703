import React from 'react';
import './MyPredictHistory.css';
import Header4 from '../../subComponent/Header4/Header4';
import { useEffect, useState } from "react"
import AuthUser from '../../AuthUser/AuthUser';
import Fade from 'react-reveal/Fade'; // Importing Fade effect
import { Link } from 'react-router-dom';


const MyPredictHistory = () => {
    const { http, user } = AuthUser();
    const [predicData, getData] = useState([]); 
    const [resDate, getRes] = useState(); 
    const [count, setCount] = useState(); 
    console.log(predicData);
    const fetchData = () => {
        let data = new FormData()
        data.append('user_id', user.id)
        http.post('prediction-fetch', data)
            .then(response => { 
                if(response.data.status == 'warning'){
                    getRes("notfound");
                    setCount("0");
                }else{
                    getData(response.data);
                    const length = response.data.length
                    setCount(length);
                    getRes("success");
                }
          
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        fetchData()
        fetchUser()
    }, [])

    // fetch User
    const [setUser, getUser] = useState('');
    const fetchUser = () => {
        let data = new FormData()
        data.append('user_id', user.id)
        http.post('userdetails', data)
            .then(response => {
                getUser(response.data);
                
            })
            .catch(err => console.log(err))
    }


    
        return (
            <Fade>
                <div className='Main-MPH-Conatainer'>
                    <div>
                        <Header4 />
                    </div>
                    <div>

                        <div className='MP-Div'>

                            <p className='MadePre-Text'> {count == '' || count == null ? '0' : count} Predictions Made</p>

                          <Link className='submit-Link-1' to="/SubmitPredict">  Predict Now</Link> 

                        </div>


                        <div id="dvCustomersGrid"></div>
                        <div className='Table-All' >
                            {resDate == "success" ?(

                          
                            <table className="table table-striped ">
                                <thead>
                                    <tr><th className='Th-Heading-2 text-center'>Champion</th><th className='Th-Heading-2 text-center'>1st Runner Up</th><th className='Th-Heading-2 text-center'>2nd Runner Up</th></tr>
                                </thead>
                                <tbody>

                                    {predicData.map((items,i) => ( 
                                        <tr className='Country-List' key={i} data-count={i}>
                                            <td className='text-center'><img src={items.champion} className="CF-Img" /></td>
                                            <td className='text-center'> <img src={items.first_runner} className="CF-Img" /></td>
                                            <td className='text-center'> <img src={items.second_runner} className="CF-Img" /></td>
                                        </tr>
                                    )
                                      
                                    )
                                    }

                                </tbody>
                            </table>
                              ) : (
                                <> 
                                </>
                              )
                              }
                        </div>
                    </div>
                </div>
            </Fade>
        )
 
}
export default MyPredictHistory;