import { appws } from './Firebase-Config';
import { useState} from 'react';
import AuthUser from '../AuthUser/AuthUser';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
const auths = getAuth(appws);

// Sign In With Gamil
export default function SocialAuth() {

    const { http, setToken } = AuthUser();
    let [gmailUser, setGmailUser] = useState();

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auths, provider)
            .then((re) => {
                if(re.user){
                    let data = new FormData() 
                    data.append(`firstName`, re.user.firstName)
                    data.append(`lastName`, re.user.lastName)
                    data.append(`displayName`, re.user.displayName)
                    data.append(`photoURl`, re.user.photoURL)
                    data.append(`email`, re.user.email)  
                    http.post('gmailSignup', data)
                        .then(function (result) {
                            if (result.data.status === "success") {
                                setTimeout(() => {
                                    setToken(result.data.user, result.data.access_token);
                                }, 1000);
                            }
                            // setMsg(null);
                            // setMsg(alertMessage(result.data.status, result.data.res));
                        })
                        .catch(err => console.log(err))
                }
                


            })
            .catch((err) => {
                // if (err.code == 'auth/popup-blocked') {
                //     signInWithRedirect(provider);
                // }
            })

    }




 
    return { 
        signInWithGoogle,
       
    }

}
// Insert Gmail User Data
