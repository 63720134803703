import React from 'react'
import './SaveProfile.css';
import Header from '../../subComponent/Header/Header';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from 'react-router-dom';
import AuthUser from '../../AuthUser/AuthUser';
import userImg from '../../../Image/user.png';
import Fade from 'react-reveal/Fade'; // Importing Fade effect
import Adds from '../../subComponent/addvertisement/adds';
import Advertisements from '../../subComponent/Advertisements/Advertisements';

const SaveProfile = () => {
    const {user} = AuthUser();
    return ( 
        <Fade>
        <div className='Save-Main-Container'> 
            <div>
                <Header />
            </div> 
            <div className='User-Photo-div-1'>

                <div className="save-profile-avater">
                        <img src={user.profile == '' ? userImg : user.profile } />
                </div>

                <p className='name-Text'>  {user.username} </p>
                <p className='Email-Text'> {user.email} </p>

            </div>


            <div className='Predict-Refer-div'>

                <div className='PL-Div'>
                    <p className='PL-Text'>Predictions <br /> Left</p>
                    <p className='PL-Num'>{user.chances}</p>
                </div>

                <div className='UR-Div'>
                    <p className='UR-Text'>User <br /> Referred</p>
                    <p className='UR-Num'>{user.userRefer}</p>
                </div>

            </div>

            <div className='PHE-Btn-Div'>

                <Link to='/MyPredictHistory' className='MPH-Btn' > My Previous History </Link>
                <Link to='/EditProfile' className='EP-Btn'> <BorderColorIcon sx={{ fontSize: '15px' }} className='Border-icon' /> Edit Profile</Link>

            </div>


            <Link className='Refer-Link' to='/ReferUser'>

                <div className='Refer-div-1'>
                    <p className="New-div">Refer New User</p>
                    <p className='Pre-Tym-div'>All User can predict 1 Time. however<br />
                        you can get up to <b>10 Predictions chance <br />if you refer new user</b>
                    </p>
                </div>
            </Link>

                {/* <Adds></Adds> */}
                <Advertisements />


        </div>
        </Fade>
    )
}

export default SaveProfile