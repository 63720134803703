import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import './Snackbar.css'
import { GiRibbonMedal } from 'react-icons/gi';
import { useState, useEffect } from 'react';
import AuthUser from '../../AuthUser/AuthUser';
import TriggersTooltips from '../Tooltip/Tooltip'

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

export default function DirectionSnackbar({base_prize}) {
    const [open, setOpen] = React.useState(false);
    const [transition, setTransition] = React.useState(undefined);

    const handleClick = (Transition) => () => {
        setTransition(() => Transition);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };




    return (
        <div>

            <div className='Snack-Btn' >

                <div className='Btn-On'>
                    <p className='CP-text'><GiRibbonMedal /> Current Prize</p>
                    <div className='kkmustpret'>
                    <p className='Prize-text' onClick={handleClick(TransitionDown)} > RM {base_prize||0} </p>
                    <TriggersTooltips /> 
                    </div>

                </div>

               
            </div>
            <Snackbar
                open={open}
                onClose={handleClose}
                TransitionComponent={transition}
                message="Current Prize is based of Total registered users."
                key={transition ? transition.name : ''}

            />
        </div>
    );
}
