import React, { useState, useEffect } from 'react';
import copy from "copy-to-clipboard";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AuthUser from '../../AuthUser/AuthUser';
import './ClipCopy.css';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const CopyBtn = () => {
    const { user } = AuthUser();
    const [copyText, setCopyText] = useState('');
    const referal = user.referal;
    const handleCopyText = (e) => {
        setCopyText('https://3prediction.com/SignUp?ref=' + referal);

    }

    const copyToClipboard = () => {
        handleCopyText()
        copy(copyText);
        setOpen(true);

    }
    useEffect(() => {
        handleCopyText()
    })

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (
        <div className='CL-Link-Div'>
            <p className='referral-text'>{referal}</p>


            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copied!"
                    >
                        <Button onClick={handleTooltipOpen} ><ContentCopyIcon onClick={copyToClipboard} className='Copy-Icon' /></Button>
                    </Tooltip>
                </div>
            </ClickAwayListener>








        </div>
    );

}


export default CopyBtn;