import React from 'react';
import './SubmitPredict.css';
import Header from '../../subComponent/Header/Header';
import DirectionSnackbar from '../../subComponent/Snackbar/Snackbar';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react"
import AuthUser from '../../AuthUser/AuthUser';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IdModal from '../../subComponent/Modal/idModal.jsx';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { BiChevronDown } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Adds from '../../subComponent/addvertisement/adds';
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Advertisements from '../../subComponent/Advertisements/Advertisements';
const drawerBleeding = 56;
const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));



const SubmitPredict = (props) => {
    const navigate = useNavigate();

    // Fetch teams
    const [Teams, setTeams] = useState([]);
    const [originalTeams,setOriginalTeams] = useState([])
    const fetchTeam = () => {
        fetch("https://admin.3prediction.com/api/teams")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setTeams(data)
                setOriginalTeams(data)
            })
    }
    useEffect(() => {
        fetchTeam()
    }, [])

    //   
    // fetch User

    const { http, user, alertMessage, token } = AuthUser();
    const [setUser, getUser] = useState('');


    const fetchUser = () => {
        let data = new FormData()
        data.append('user_id', user.id)
        http.post('userdetails', data)
            .then(response => {
                getUser(response.data);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (token) {
            fetchUser();
        }
    }, [])
    // Champion Team

    const { window } = props;
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };


    const [champId, getChamp] = useState('');
    const [ChampTeamName, GetChampTeamName] = useState('');
    function setChampTeam(event) {
        const id = event.target.id;
        GetChampTeamName(event.target.getAttribute('champ-team-name'));
        getChamp(id);
        let filterData = originalTeams?.filter(each =>( each.id != id && each.id != fRunUpId && each.id != sRunUpId  ))
        setTeams(filterData);
    }

    // 1st Runner Up Team
    const [open1, setOpen1] = React.useState(false);
    const toggleDrawer1 = (newOpen1) => () => {
        setOpen1(newOpen1);
    };

    const [fRunUpId, getfRunUp] = useState('');
    const [fRunUpTeamName, GetfRunUpTeamName] = useState('');
    function setRunUp(event) {
        const id = event.target.id;
        GetfRunUpTeamName(event.target.getAttribute('RunUp-team-name'));
        getfRunUp(id);

        let filterData = originalTeams?.filter(each =>( each.id != id && each.id != champId && each.id != sRunUpId  ))
        setTeams(filterData);
    }

    // 2nd Runner Up Team
    const [open2, setOpen2] = React.useState(false);
    const toggleDrawer2 = (newOpen1) => () => {
        setOpen2(newOpen1);

    };
    const [sRunUpId, getsRunUp] = useState('');
    const [SRunUpTeamName, GetSRunUpTeamName] = useState('');
    function set2Team(event) {
        const id = event.target.id;
        GetSRunUpTeamName(event.target.getAttribute('SrunUp-team-name'));
        getsRunUp(id);

        let filterData = originalTeams?.filter(each =>( each.id != id && each.id != champId && each.id != fRunUpId  ))
        setTeams(filterData);
    }


    // Submit Prediction

    const [msg, setMsg] = useState(null);
    const submitPrediction = async (e) => {
        e.preventDefault();
        if(!token){
            const status = "warning";
            const mssg = "You Need To Login Your Account For Prediction";
            setMsg(alertMessage(status, mssg));
            Swal.fire({
                title: 'Prediction Failed',
                text: 'You Need To Login Your Account For Prediction',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Login",
                cancelButtonText: "Cancel",
                icon: 'warning'
            }).then((result) => {if (result.isConfirmed) { navigate('/Login') }})
        } else { 
            if (user.idnumber == 'Ok') {
                if (champId != "" && fRunUpId != "" && sRunUpId != "") {
                    if (user.chances > 0) {
                        let data = new FormData()
                        data.append('champion', champId)
                        data.append('first_runner', fRunUpId)
                        data.append('second_runner', sRunUpId)
                        data.append('user_id', user.id)
                        http.post('prediction', data)
                            .then(function (result) {
                                if (result.data.status == "success") {
                                    console.log()
                                    fetchUser();
                                    navigate('/Thankyou')
                                }
                                setMsg(null);
                                setMsg(alertMessage(result.data.status, result.data.msg));
                            })
                            .catch(err => console.log(err))
                    } else {
                        const status = "warning";
                        const mssg = "You Have no more chances left ";
                        setMsg(alertMessage(status, mssg));
                    }
                } else {
                    const status = "warning";
                    const mssg = "Please Select Teams";
                    setMsg(alertMessage(status, mssg));
                }
            } else {
                const status = "warning";
                const mssg = "Register You National Id";
                setMsg(alertMessage(status, mssg));
            }

        }

    }
 
    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    const [settings, setSettings] = useState();
    const fetchSettingsData = () => {
        http
          .post("/settings")
          .then((res) => {
            console.log(res?.data);
            if (res.data.status == "success") {
              setSettings(res.data?.settings);
            }
          })
          .catch((err) => console.log(err));
    };
    useEffect(() => {
        fetchSettingsData();
      }, []);



    return (



        <div className='Main-Submit-Container'>
          
            <div className='submitprediction'>
                {token && user.idnumber == 'notOk' ? (
                    <>
                        <IdModal />
                    </>
                ) : (
                    <></>
                )
                }
                <Header />
            </div>

            <div className='Prize-Div-1'>

                <p className='World-text-1'>{settings?.home_page_title || ""}</p>

                <p className='Winner-Text-1'>Predict the Winner & Runner Up
                    and Win the Prize
                </p>

                <DirectionSnackbar className="sp_prize"  base_prize={settings?.base_prize}  />
                {token ? (
                    <div className='Pre-Btn-Div'>
                        <p className='Pre-Text-1'>Predictions Left </p>
                        <p className='Pre-Num-1'>{setUser.chances}</p>
                    </div>
                ) : (
                    <div className='Pre-Btn-Div'>
                        <p className=' mt-10'></p>
                    </div>
                )}
            </div>

            <div className='Select-Main-Div'>
                <div id="alert" className='p-3'>
                    {msg ? msg : ""}
                </div>
                <label htmlFor="">Champion</label>
                <div>
                    <Root>
                        <CssBaseline />
                        <Global
                            styles={{
                                '.MuiDrawer-root > .MuiPaper-root': {
                                    height: `calc( - ${drawerBleeding}px)`,
                                    overflow: 'visible',
                                },
                            }}
                        />
                        <Box sx={{ textAlign: 'center', pt: 1 }}>
                            <Button disableRipple={true} className='Input-Btn select-teams-dropdown' onClick={toggleDrawer(true)}>
                                <input className='select-Input' name='NewCountry' readOnly={true} value={ChampTeamName} type="select" placeholder='-select' />
                                <BiChevronDown className='Chevron-Icon' /> </Button>
                        </Box>
                        <SwipeableDrawer
                            container={container}
                            anchor="bottom"
                            open={open}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            swipeAreaWidth={drawerBleeding}
                            disableSwipeToOpen={false}
                            ModalProps={{
                                keepMounted: true,
                            }}
                        >
                            <div className='Bottom-Div'>
                                {Teams.length > 0 && (

                                    <ul className='Country-list'>
                                        {Teams.map(Team => (

                                            <button onClick={toggleDrawer(false)} className="List-Btn">

                                                <li className='FN-List' id={Team.id} champ-team-name={Team.name} onClick={setChampTeam}>
                                                    <img className='Flag' src={Team.image} />  {Team.name}
                                                </li>
                                            </button>

                                        ))}

                                    </ul>
                                )}
                            </div>
                        </SwipeableDrawer>
                    </Root>

                </div>


            </div>
            <div className='Select-Main-Div'>

                <label htmlFor="">1st Runner Up</label>

                <div>
                    <Root>
                        <CssBaseline />
                        <Global
                            styles={{
                                '.MuiDrawer-root > .MuiPaper-root': {
                                    height: `calc( - ${drawerBleeding}px)`,
                                    overflow: 'visible',
                                },
                            }}
                        />
                        <Box sx={{ textAlign: 'center', pt: 1 }}>
                            <Button disableRipple={true} className='Input-Btn select-teams-dropdown' onClick={toggleDrawer1(true)} >
                                <input className='select-Input' name='NewCountry' readOnly={true} value={fRunUpTeamName} type="select" placeholder='-select' />
                                <BiChevronDown className='Chevron-Icon' /> </Button>
                        </Box>
                        <SwipeableDrawer
                            container={container}
                            anchor="bottom"
                            open={open1}
                            onClose={toggleDrawer1(false)}
                            onOpen={toggleDrawer1(true)}
                            swipeAreaWidth={drawerBleeding}
                            disableSwipeToOpen={false}
                            ModalProps={{
                                keepMounted: true,
                            }}
                        >
                            <div className='Bottom-Div'>
                                {Teams.length > 0 && (

                                    <ul className='Country-list'>
                                        <button onClick={toggleDrawer1(false)} className="List-Btn">
                                            {Teams.map(Team => (
                                                <li className='FN-List' onClose={toggleDrawer(false)} id={Team.id} RunUp-team-name={Team.name} onClick={setRunUp}>
                                                    <img className='Flag' src={Team.image} />  {Team.name}
                                                </li>
                                            ))}
                                        </button>
                                    </ul>
                                )}
                            </div>
                        </SwipeableDrawer>
                    </Root>

                </div>

            </div>
            <div className='Select-Main-Div'>

                <label htmlFor="">2nd Runner Up</label>
                <div>
                    <Root>
                        <CssBaseline />
                        <Global
                            styles={{
                                '.MuiDrawer-root > .MuiPaper-root': {
                                    height: `calc( - ${drawerBleeding}px)`,
                                    overflow: 'visible',
                                },
                            }}
                        />
                        <Box sx={{ textAlign: 'center', pt: 1 }}>
                            <Button disableRipple={true} className='Input-Btn select-teams-dropdown' onClick={toggleDrawer2(true)}>
                                <input className='select-Input' name='NewCountry' readOnly={true} value={SRunUpTeamName} type="select" placeholder='-- select --' />
                                <BiChevronDown className='Chevron-Icon' /> </Button>
                        </Box>
                        <SwipeableDrawer
                            container={container}
                            anchor="bottom"
                            open={open2}
                            onClose={toggleDrawer2(false)}
                            onOpen={toggleDrawer2(true)}
                            swipeAreaWidth={drawerBleeding}
                            disableSwipeToOpen={false}
                            ModalProps={{
                                keepMounted: true,
                            }}
                        >
                            <div className='Bottom-Div'>
                                {Teams.length > 0 && (
                                    <ul className='Country-list'>
                                        <button onClick={toggleDrawer2(false)} className="List-Btn">
                                            {Teams.map(Team => (
                                                <li className='FN-List' id={Team.id} SrunUp-team-name={Team.name} onClose={toggleDrawer2(false)} onClick={set2Team}>
                                                    <img className='Flag' src={Team.image} />  {Team.name}
                                                </li>
                                            ))}

                                        </button>

                                    </ul>
                                )}
                            </div>
                        </SwipeableDrawer>
                    </Root>

                </div>


            </div>

            <button className='Submit-Btn-Go Predict-Submit-Btn' onClick={submitPrediction}> Submit Prediction</button>
            {token ? (
                <Link className='Refer-Link' to='/ReferUser'>
                    <div className='Refer-div-4'>
                        <p className="New-div">Refer New User</p>
                        <p className='Pre-Tym-div'>All User can predict 1 Time. however<br />
                            you can get up to <b>10 Predictions chance <br />if you refer new user</b>
                        </p>
                    </div>
                </Link>
            ) : (
                <>
                <div className="pt-10"></div>
                <div className="pt-10"></div>   
                <div className="pt-10"></div>   
                </>
            )
            }

            {/* <Adds></Adds> */}
            <Advertisements />



        </div >

    )
}

export default SubmitPredict