import React from 'react';
import Header2 from '../../subComponent/Header2/Header2';
import './AllUserPredict.css';
import AuthUser from '../../AuthUser/AuthUser';
import { useState, useEffect } from 'react';

const AllPredictUser = () => {

    const { http } = AuthUser();
    // All user Prediction
    const [dataResult, setDataResult] = useState();
    const [data, getData] = useState([]);
    const numDescending = [...data].sort((a, b) => b.totaluser - a.totaluser);
    const fetchData = () => {
        http.post('prediction-fetch-overall')
            .then(response => {
                if(response.data.status == "warning"){
                    setDataResult("error");
                }else{
                    setDataResult("success");
                    getData(response.data);
                }

            })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (

        <div className='Main-AllUserPredict-Container'>

            <div>


                <Header2 />

            </div>

            <div className='Table-All' >

                <table className="table table-striped ">
                    <thead>
                    <tr>
                        <th className='Th-Heading-2'>Champion</th>
                        <th className='Th-Heading-2'>1st Runner Up</th>
                        <th className='Th-Heading-2'>2nd Runner Up</th>
                        <th className='Th-Heading-2'>Users</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataResult == "success"?
                    numDescending.map((item, i) => (
                        <tr className='Country-List' key={i}>
                            <td className='text-center'> <img src={item.champion} className="CF-Img" /> </td>
                            <td className='text-center'> <img src={item.first_runner} className="CF-Img"/></td>
                            <td className='text-center'> <img src={item.second_runner} className="CF-Img"/></td>
                            <td className='text-center'> {item.totaluser}</td>
                        </tr>
                    ))
                    :(<></>)
                }
                </tbody>


                </table>

            </div>
        </div>
    )
}


export default AllPredictUser;
