import React from 'react';
import Header from '../../subComponent/Header/Header';
import './Home.css';
import DirectionSnackbar from '../../subComponent/Snackbar/Snackbar';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AuthUser from '../../AuthUser/AuthUser';
import Fade from 'react-reveal/Fade'; // Importing Fade effect 
import IdModal from '../../subComponent/Modal/idModal.jsx';
import Adds from '../../subComponent/addvertisement/adds';
import Advertisements from '../../subComponent/Advertisements/Advertisements';

const Home2 = () => {

    const { user, http, token } = AuthUser();

    // All user Prediction
    const [data, getData] = useState([]);
    const [dataRes, getDataRes] = useState();
    const allPreDescending = [...data].sort((a, b) => b.totaluser - a.totaluser);
    const fetchData = () => {
        http.post('prediction-fetch-overall')
            .then(response => {
                if (response.data.status == "warning") {
                    getDataRes("error");
                } else {
                    getDataRes("success");
                    getData(response.data);
                }

            })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        fetchData()
    }, [])


    // All Prediction Score
    const [scoreRes, getScoreRes] = useState();
    const [score, getScore] = useState([]);
    const numDescending = [...score].sort((a, b) => b.score - a.score);
    const fetchScore = () => {
        http.post('championscore')
            .then(response => {
                if (response.data.status == "warning") {
                    getScoreRes("error");
                } else {
                    getScoreRes("success");
                    getScore(response.data);
                }
            })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        fetchScore()
    }, [])


    const [settings, setSettings] = useState();
    const fetchSettingsData = () => {
        http
          .post("/settings")
          .then((res) => {
            console.log(res?.data);
            if (res.data.status == "success") {
              setSettings(res.data?.settings);
            }
          })
          .catch((err) => console.log(err));
    };
    useEffect(() => {
        fetchSettingsData();
      }, []);


    if (scoreRes != "" && dataRes != "") {
        return (
            <Fade>
                <div className='Main-Home-Container'>
                    <div>
                        <Header />
                    </div>
                    <div className='Home-Main-Div'>


                        <div className='Prize-Div'>

                          <p className="World-text">{settings?.home_page_title || ""}</p>

                            <p className='Winner-Text'>Predict the Winner & Runner Up
                                and Win the Prize
                            </p>

                            <DirectionSnackbar  base_prize={settings?.base_prize}  />

                            <div className='home-pwa justify-content-center mt-10'>
                                <button className='PN-btn home-pwa-btn'><Link className='submit-Link' to="/SubmitPredict">  Predict Now</Link> </button>
                            </div>

                        </div>

                        <div className='AUP-table'>

                            <p className='table-Head'>All Prediction User</p>

                            <table className='Country-Tb allpt hApu' cellSpacing="0" cellPadding="6px">
                                <thead>
                                    <tr className='Tr-heading'>
                                        <th className='th-Heading'>Champion</th>
                                        <th className='th-Heading'>1st Runner Up</th>
                                        <th className='th-Heading'>2st Runner Up</th>
                                        <th className='th-Heading'>Users</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataRes == "success" ?
                                        allPreDescending.map((item, i) => (

                                            <tr className='Country-List' key={i}>
                                                <td className='text-center'> <img src={item.champion} className="CF-Img" /></td>
                                                <td className='text-center'> <img src={item.first_runner} className="CF-Img" /></td>
                                                <td className='text-center'> <img src={item.second_runner} className="CF-Img" /></td>
                                                <td className='text-center'> {item.totaluser}</td>
                                            </tr>

                                        ))
                                        : (
                                            <tr>
                                                <td className='NDF'>
                                                    <div className='text-center'> No Data Found</div>
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>

                            </table>

                            <p className='table-Footer'> <Link className='right-Link' to="/AllPredictUser">View More <AiOutlineArrowRight className='right-arr' /> </Link></p>

                        </div>
                        <div className='AUP-table'>

                            <p className='table-Head'>Champion Prediction Statistic</p>

                            <table className='Country-Tb allpt hApu' cellSpacing="0" cellPadding="6px">
                                <thead>
                                    <tr className='Tr-heading'>
                                        <th className='TN-Heading'>Team</th>
                                        <th className='TN-Heading'>% of User Predictions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scoreRes == "success" ?
                                        numDescending.map((item, i) => (
                                            <tr className='Country-List' key={i}>
                                                <td className='d-flex align-items-center '>
                                                    <img className='Flag-Icon mr-2 ml-4 ' src={item.flag} /> {item.team}</td>
                                                <td className='text-end bold pr-4'> {item.score}%</td>
                                            </tr>
                                        ))

                                        : (
                                            <tr>
                                                <td className='NDF'>
                                                    <div className='text-center'> No Data Found</div>
                                                </td>
                                            </tr>

                                        )
                                    }
                                </tbody>
                            </table>

                            <p className='table-Footer'> <Link className='right-Link' to="/AllPredictStatistic">View More <AiOutlineArrowRight className='right-arr' /> </Link></p>

                        </div>

                        {token ? (
                            <Link className='Refer-Link' to='/ReferUser'>

                                <div className='Refer-div-3'>
                                    <p className="New-div-3">Refer New User</p>
                                    <p className='Pre-Tym-div-3'>All User can predict 1 Time. however<br />
                                        you can get up to <b>1 Predictions chance <br />if you refer new user</b>
                                    </p>
                                </div>

                            </Link>
                        ) : (
                            <>
                            <div className="pt-10"></div>
                            <div className="pt-10"></div>
                            </>
                        )
                        }
                        {/* <Adds></Adds> */}
                        <Advertisements />

                    </div>

                </div>
            </Fade>
        )
    } else {
        return (
            <div className="home-skeleton">
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" height={60} />
                    <div className="skeleton-body">
                        {/* For variant="text", adjust the height via font-size */}
                        <Skeleton variant="" className="skele-rounded" height={250} sx={{ fontSize: '1rem', marginBottom: '1.5rem !important' }} />
                        <Skeleton variant="" className="skele-rounded" height={250} sx={{ fontSize: '1rem', marginBottom: '1.5rem !important' }} />
                        <Skeleton variant="" className="skele-rounded" height={250} sx={{ fontSize: '1rem', marginBottom: '1.5rem !important' }} />
                        <Skeleton variant="" className="skele-rounded" height={150} sx={{ fontSize: '1rem', marginBottom: '3.5rem !important' }} />

                        {/* For other variants, adjust the size with `width` and `height` */}
                    </div>
                </Stack>
            </div>
        )
    }

}

export default Home2