import * as firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyDxRP_5nWQCsBABC3gRNIH-P8PjsQGq-KM",
    authDomain: "top-3-prediction.firebaseapp.com",
    projectId: "top-3-prediction",
    storageBucket: "top-3-prediction.appspot.com",
    messagingSenderId: "440917993993",
    appId: "1:440917993993:web:48e43f6686d753efd326d8",
    measurementId: "G-3TXXM3768G"
  };
  
  // Initialize Firebase
  const appws = firebase.initializeApp(firebaseConfig);
  export  {
    appws
  }
  