import React, { useState } from 'react';
import './VerifyAccount.css';
import Header from '../../subComponent/Header/Header'
import AuthUser from '../../AuthUser/AuthUser';
import { Zoom } from 'react-reveal';
import { useNavigate } from 'react-router';
const VerifyAccount = () => {
    const { http, user, alertMessage, setToken, token } = AuthUser();
    const navigate = useNavigate();
    if (user.verified == "verified") {
        navigate('/');
    }
    const [msg, setMsg] = useState(null);

    const [verifySend, setVerifySend] = useState(false);
    const [loader, setLoader] = useState(false);
    const handleVerify = () => {
        let data = new FormData()
        setLoader(true);
        data.append('userid', user.id)
        http.post('verify', data)
            .then(function (result) {
                if (result.data.data.status == "success") {
                    setVerifySend(true);
                    setLoader(false);
                }

            })
            .catch(err => console.log(err))
    }

    const [verifySuccess, setVerifySuccess] = useState(false);
    const [verifyCode, setVerifyCode] = useState('');
    const handleSendCode = () => {
        setMsg(null);
       let countCode = parseInt(verifyCode.length);
        if(verifyCode != '' && countCode == 6){
        let data = new FormData()
        setLoader(true);
        data.append('userid', user.id)
        data.append('verifyCode', verifyCode)
        http.post('verifycode', data)
            .then(function (result) {
                if (result.data.status == "success") {
                    setVerifySuccess(true)
                    setTimeout(() => {
                        setToken(result.data.user, token);
                    }, 2000);
                }

            })
            .catch(err => console.log(err))
        } else{
            const status = "error";
            const msg = "Incorrect Code";
            setMsg(null);
            setMsg(alertMessage(status, msg));
        }
    }

    return (
        <div className='email-verification'>
            <Header />
            <div className="email-veri-cont">
            <div id="alert" className='p-3'>
                           
                           {msg ? msg : ""}

                       </div>
                {verifySuccess ? (
                    <>
                        <h4>Successfully Verified !</h4>
                        <p>Congratulation you got <span>1 prediction chance</span></p>
                    </>
                ) : (
                    <>
                        <h4>Verify Your Email  </h4>
                        <p>Verify your email and get <span>1 prediction chance</span></p>
                    </>
                )
                }

                {verifySend ? (

                    <> 
                     
                        <Zoom>
                        <span>{verifySuccess ? "Verification Code Successfully send on your email*" : ""}</span> 
                        <p className='verfication-text'>Verfication code send on your <b>{user.email}</b></p>
                            <input type="text" placeholder='Enter Verfication Code' onChange={e => setVerifyCode(e.target.value)} />
                        </Zoom>
                        <button className='VA-Btn' type="submit" onClick={handleSendCode} > VERIFY ACCOUNT <i className={loader ? 'fa fa-spin fa-spinner' : ''}></i></button>
                    </>
                ) : (

                    <button className='VA-Btn' type="submit" onClick={handleVerify} > VERIFY ACCOUNT <i className={loader ? 'fa fa-spin fa-spinner' : ''}></i></button>
                )
                }
            </div>
        </div >
    )
}

export default VerifyAccount