import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WelScreen from '../Component/Pages/Welcome Screen/WelScreen';
import SignUp from '../Component/Pages/Sign Up/SignUp';
import Login from '../Component/Pages/Login/Login';
import ForgotPassword from '../Component/Pages/forgot/forgot';
import EditProfile from '../Component/Pages/Edit Profile/EditProfile';
import SaveProfile from '../Component/Pages/SaveProfile/SaveProfile';
import ReferUser from '../Component/Pages/ReferUser/ReferUser';
import Home from '../Component/Pages/HomeScreen/Home';
import Home2 from '../Component/Pages/HomeScreen/Home2';
import AllPredictUser from '../Component/Pages/AlLPerdictUser/AllPredictUser';
import AllPredictStatistic from '../Component/Pages/AllPredictStatistic/AllPredictStatistic';
import SubmitPredict from '../Component/Pages/SubmitPrediction/SubmitPredict';
import Thankyou from '../Component/Pages/Thankyou/Thankyou';
import MyPredictHistory from '../Component/Pages/MyPredictHistory/MyPredictHistory';
import Winner from '../Component/Pages/Winner/winner';
import AuthUser from '../Component/AuthUser/AuthUser';
import VerifyAccount from '../Component/Pages/VerifyAccount/VerifyAccount';


const MyRouter = () => {
  const { getToken } = AuthUser();


  //const RedirectRoute = () => {

  //     if(getToken() ){
  //       <redirect to ='/Home' /> 
  //     }
  //     else{
  //       <WelScreen />  
  //     }   
  //   }

  //   useEffect(() => {
  //   //RedirectRoute()

  // })
  if (getToken()) {
    <redirect to='/Home' />
    return (
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/Home2' element={<Home />} />
        <Route path='/WelScreen' element={<WelScreen />} />
        <Route path='/SignUp/' element={<SignUp />} />
        <Route path='/SignUp/:ref' element={<SignUp />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/EditProfile' element={<EditProfile />} />
        <Route path='/SaveProfile' element={<SaveProfile />} />
        <Route path='/ReferUser' element={<ReferUser />} />
        <Route path='/AllPredictUser' element={<AllPredictUser />} />
        <Route path='/AllPredictStatistic' element={<AllPredictStatistic />} />
        <Route path='/SubmitPredict' element={<SubmitPredict />} />
        <Route path='/Thankyou' element={<Thankyou />} />
        <Route path='/MyPredictHistory' element={<MyPredictHistory />} />
        <Route path='/Winner' element={<Winner />} />
        <Route path='/VerifyAccount' element={<VerifyAccount />} /> 
      </Routes>

    )
  } else if (!getToken()) {
    return (
      <Routes> 
        {/* <Route path='/' element={<WelScreen />} /> */}
        <Route path='/ForgotPassword' element={<ForgotPassword />} />
        <Route path='/SignUp' element={<SignUp />} />   
        <Route path='/WelScreen' element={<WelScreen />} />
        <Route path='/' element={<Home2 />} />
        <Route path='/Home2' element={<Home2 />} />
        <Route path='/Home' element={<Home2 />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/EditProfile' element={<EditProfile />} />
        <Route path='/SaveProfile' element={<SaveProfile />} />
        <Route path='/ReferUser' element={<ReferUser />} />
        <Route path='/AllPredictUser' element={<AllPredictUser />} />
        <Route path='/AllPredictStatistic' element={<AllPredictStatistic />} />
        <Route path='/SubmitPredict' element={<SubmitPredict />} />
        <Route path='/Thankyou' element={<Thankyou />} />
        <Route path='/MyPredictHistory' element={<MyPredictHistory />} />
        <Route path='/Winner' element={<Winner />} />
        <Route path='/VerifyAccount' element={<VerifyAccount />} />



      </Routes>
    )
  }
}

export default MyRouter;