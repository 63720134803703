import React, { useState } from 'react';
import './SignUp.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faG } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import Eye from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import AuthUser from '../../AuthUser/AuthUser';
import { useNavigate } from 'react-router-dom';
import socialAuth from '../../Config/authMethod';
import { FiEyeOff } from 'react-icons/fi';
import { FiEye } from 'react-icons/fi';
import Fade from 'react-reveal/Fade'; // Importing Fade effect;
import fbIcon from '../../../Image/facebook.png';
import { useLocation } from "react-router-dom"
import StickyHomeButton from '../../subComponent/StickyHomeButton/StickyHomeButton';
const SignUp = () => {
    const { signInWithGoogle, signInWithFacbook } = socialAuth();
    const navigate = useNavigate();  
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    let ref = params.get("ref"); 
    if(ref == null){
        ref= '';
    }
    const { http, alertMessage } = AuthUser();
    const [Loader, setLoader] = useState(false);
    const [idnumber, setIdNumber] = useState('');
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [nickname, setNickname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState(null);
    const registerUser = async (e) => {
        e.preventDefault();
        // setTimeout(() => {
        //     setMsg(null);
        // }, 5000);
        setLoader(true);
        var countId = parseInt(idnumber.length); 
        if(countId == 6){
        if (firstname != '' && lastname != '' && nickname != '' && username != '' && email != '' && password != '' && idnumber != '') {
            let data = new FormData()
          
            data.append('idnumber', idnumber)
            data.append('email', email)
            data.append('password', password)
            data.append('firstName', firstname)
            data.append('lastName', lastname)
            data.append('nickname', nickname)
            data.append('username', username)
            data.append('ref', ref)
            http.post('adduser', data)
                .then(function (result) {
                    if (result.data.status == "success") {
                        setTimeout(() => {
                            navigate('/Login');
                        }, 1500);
                       
                    }
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    setLoader(false);
                    setMsg(null);
                    setMsg(alertMessage(result.data.status, result.data.res));
                })
                .catch(function (err){
                    setLoader(true);
                   
                    setTimeout(() => {
                        setLoader(false);
                        
                    }, 4000);
                    const status = "error";
                    const msg = "Faild to SignUp";
                    setMsg(null);
                    setMsg(alertMessage(status, msg));
                    window.scrollTo({top: 0, behavior: 'smooth'});
                })
        } else {
            setLoader(false);
            const status = "warning";
            const msg = "Fill all fields";
            setMsg(null);
            setMsg(alertMessage(status, msg));
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }else {
        const status = "warning";
        const msg = "6 Digit ID Required*";
        setMsg(null);
        setLoader(false);
        setMsg(alertMessage(status, msg));
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    }

    const [changePassword, setChangePassword] = useState(true);
    const changeIcon = changePassword === true ? false : true;

    return (
        <Fade>
            <div className='Main-SignUp-Container'>
            <StickyHomeButton />
                <div>
                    <p className='SignUp-heading' onClick={()=>{navigate('/')}}>Top3 Prediction</p>

                    <p className='create-heading'>Create a New Account</p>
                </div>

                <div className='input-Div'>
                    <form onSubmit={registerUser} className="row">
                        <div id="alert" className='p-3'>
                           
                            {msg ? msg : ""}

                        </div>
                        <div className='Nick-div'>

                            <label htmlFor="">National ID Number</label> 
                            <input type="text" name='idnumber' maxLength="6" placeholder='Last 6 Digit Number (018292)' onChange={e => setIdNumber(e.target.value)} />
                             <i className='id_warning'>warning:  Verification  needed for any withdrawal</i> 
                           
                        </div>
                        <div className='Nick-div w-40'>

                            <label htmlFor="">First Name</label>

                            <input type="text" name='fname' maxLength="20" placeholder='First Name' onChange={e => setfirstname(e.target.value)} />

                        </div>
                        <div className='Nick-div w-40 mr-25'>

                            <label htmlFor="">Last Name</label>

                            <input type="text" name='nickname' maxLength="20" placeholder='Last Name' onChange={e => setlastname(e.target.value)} />

                        </div>
                        <div className='User-div'>

                            <label htmlFor="">Nickname</label>
                            <input type="text" name='username' maxLength="20" placeholder='Your nickname' onChange={e => setNickname(e.target.value)} />
                             <i className='id_warning'>This name show in public</i> 

                        </div>
                        <div className='User-div'>

                            <label htmlFor="">Username</label>

                            <input type="text" name='username' maxLength="20" placeholder='Your Username' onChange={e => setUsername(e.target.value)} />

                        </div>
                        <div className='Email-div'>

                            <label htmlFor="">Email</label>

                            <input type="email" name='email' maxLength="30" placeholder='youremail@yourdomain' onChange={e => setEmail(e.target.value)} />

                        </div>
                        <div className='Pass-div'>

                            <label htmlFor="">Password</label>

                            <input type={changePassword ? "password" : "text"} name='password' maxLength="20" placeholder='Password (min 6 character)' onChange={e => setPassword(e.target.value)} />

                            <span className="icon-eye-2"
                                onClick={() => {
                                    setChangePassword(changeIcon);
                                }}
                            >
                                {changeIcon ? <FiEye /> : <FiEyeOff />}
                            </span>


                        </div>

                        <div className='CA-Btn-div'>
                            <button className='CA-Btn' disabled={Loader?'disabled': '' } >
                                Create Account
                            <i className={Loader?'fa fa-spinner fa-solid fa-spin ml-3': ''}></i>
                            </button>
                            <p className='small-notic'><b>Note:</b> Fake user will not able to get withdrawal of their winning*</p>
                        </div>
                    </form>
                    <div className='sign-up-With'>
                        <hr className='sign-hr' />
                        <p className='sign-text'>or Sign Up with</p>
                        <hr className='sign-hr' />
                    </div>

                    <div className='Icon-Div'>
                        <FontAwesomeIcon icon={faG} className='gmail-Icon' onClick={signInWithGoogle} />


                        {/* <span className='fb-Icon'></span>
                    <span className='apple-Icon'></span> */}
                    </div>

                    <div className='SignupToLogin'>
                        <p className='alr-Text'>Already have an Account? <span><Link to="/Login" className='Log-Btn'>Log In</Link></span> </p>
                    </div>

                </div>

            </div>
        </Fade>
    )
}

export default SignUp;