import React from 'react';
import './ReferUser.css';
import Header from '../../subComponent/Header/Header';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AuthUser from '../../AuthUser/AuthUser';
import { useState, useEffect } from 'react';
import CopyBtn from '../../subComponent/ClipCopy/ClipCopy';
import Fade from 'react-reveal/Fade'; // Importing Fade effect
import { FacebookShareButton, WhatsappShareButton, TelegramShareButton, WorkplaceShareButton } from 'react-share';
import Adds from '../../subComponent/addvertisement/adds';
import Advertisements from '../../subComponent/Advertisements/Advertisements';

const ReferUser = () => {
    // fetch User
    const [setUser, getUser] = useState('');
    const { http, user } = AuthUser();
    const fetchUser = () => {
        let data = new FormData()
        data.append('user_id', user.id)
        http.post('userdetails', data)
            .then(response => {
                console.warn(response.data)
                getUser(response.data);
            })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        fetchUser()
    }, [])

    const referral = user.referal;

    const ShareLink = ('https://3prediction.com/SignUp?ref=' + referral)

    return (
        <Fade>
            <div className='ReferUser-Main-Container'>


                <div>
                    <Header />
                </div>


                <div className='Main-RPH-Div'>

                    <div className='RPH-Div'>


                        <div className='Predict-Refer-div-1'>


                            <div className='UR-Div-1'>
                                <p className='UR-Text-1'>User <br /> Referred</p>
                                <p className='UR-Num-1'>{setUser.userRefer}</p>
                            </div>
            


                        </div>


                        <div className='Refer-div-2'>
                            <p className="New-div">Refer New User</p>
                            <p className='Pre-Tym-div'>All User can predict 1 Time. however
                                you can predict  Up to <b> 1  Predictions chance if you refer new user</b>
                            </p>
                        </div>

                        <div className='HW-Div'>
                            <p className='Hw-Text'>How it's Works?</p>
                            <div className='One-text'>

                                <span className='Num-Span'>1</span>
                                <span className='Invite-div'>  Invite a new user to install/register  using your Link</span>
                            </div>
                            <div className='One-text'>
                                <p className='Num-Span'>2</p>
                                <p className='Invite-div'>Once the user used the prediction  chance, you will get you additional prediction chance (Max 10 Prediction)</p>
                            </div>
                        </div>


                        <CopyBtn />


                        <div className='Ref-Icon-Div'>
                            <TelegramShareButton title='3Prediciton'
                                url={ShareLink} >   <span className='Plane-Icon'></span> </TelegramShareButton>

                            <WhatsappShareButton
                                title='3Prediction '
                                url={ShareLink} >  <span className='tw-Icon'></span> </WhatsappShareButton>
                            <FacebookShareButton
                                url={ShareLink}
                                quote={"3Prediction Referral Link"}
                                hashtag="#3Prediction"
                            >
                                <span className='Face-Icon'></span>
                            </FacebookShareButton>

                            <span className='Dot-Icon'></span>
                        </div>

                    </div>

                </div>

                {/* <Adds></Adds> */}
                <Advertisements />



            </div>
        </Fade>

    )
}

export default ReferUser