// import logo from './logo.svg';
import './App.css';
// import Winner from './Component/Pages/Winner/winner';
import MyRouter from './Router/Router';
import IdModal from './Component/subComponent/Modal/idModal';
function App() {
  
  return ( 
    <div > 
    
      <MyRouter />

    </div>
    
  );
}

export default App;
