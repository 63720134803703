import React from 'react';
import './EditProfile.css';
import Header from '../../subComponent/Header/Header'
import userImg from '../../../Image/user.png';
import { Link } from 'react-router-dom';
import AuthUser from '../../AuthUser/AuthUser';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import Adds from '../../subComponent/addvertisement/adds';
import Advertisements from '../../subComponent/Advertisements/Advertisements';

const EditProfile = () => {
    const navigate = useNavigate();
    const { user, http, setToken, token, alertMessage } = AuthUser();
    // fetch User detail
    const [userdetail, setUserdetail] = useState();

    useEffect(() => {
        fetchUserDetail();
    }, []);

    const fetchUserDetail = async (e) => {
        let data = new FormData()
        data.append('user_id', user.id)
        try {
            const res = await http.post('/userdetails', data);
            setUserdetail(res.data);
        } catch (e) {
            alert(e);
        }

    }


    // Username Value Set
    const UserValue = user.nickname;
    const [name, setUserName] = useState(UserValue);

    const UsernameChange = (event) => {
        const value = event.target.value;
        setUserName(value);
    };
    // Email Value Set
    const EmailValue = user.email;
    const [email, setEmail] = useState(EmailValue);
    const emailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
    };




    const [msg, setMsg] = useState(null);
    const [password, setPassword] = useState(null);
    const [Conpassword, setConPassword] = useState(null); 
    const [profile, setProfile] = useState();
    const editProfile = async (e) => {
        e.preventDefault();
      if(Conpassword != '' && password !='' &&  Conpassword != null && password != null){
        if (Conpassword != password ) {
            const status = "warning";
            const mssg = "password not matching";
            setMsg(alertMessage(status, mssg));
        }  
    }
        let data = new FormData()
        data.append('email', email)
        data.append('pass', password)
        data.append('nickname', name)
        data.append('userid', user.id)
        data.append('profile', profile)
        http.post('profile', data)
            .then(function (result) {
                if (result.data.status == "success") {
                    setTimeout(() => {
                        setToken(result.data.user, token);
                        navigate('/SaveProfile');
                    }, 1500);
                }
                setMsg(null);
                setMsg(alertMessage(result.data.status, result.data.msg));
            })
            .catch(err => console.log(err))

    
         
    }

    if (userdetail || user.profile) {
        // Edit User Profile

        return (

            <div className='Main-Edit-Container'>

                <div>
                    <Header />
                </div>

                <div className='User-Photo-div'>

                    <img src={profile ? URL.createObjectURL(profile) : user.profile == '' ? userImg : user.profile} />

                </div>

                <div className='Btn-Input-div'>

                    <div className='CPP-Btn'>
                        <input type="file" name="profile" onChange={e => setProfile(e.target.files[0])} accept="image/png, image/gif, image/jpeg"/>
                        <span className='Fa-icon'></span>Change Profile Picture</div>

                </div>
                <form onSubmit={editProfile}>
                    <div id="alert" className='p-3'>
                        {msg ? msg : ""}
                    </div>
                    <div className='EP-Div-1'>
                        <div className='E-Div'>
                            <input type="hidden" />
                            <label>Account Name</label>

                            <input type="text" onChange={UsernameChange} value={name} />

                        </div>
                        <div className='E-Div'>

                            <label>Email Address</label>

                            <input type="email" onChange={emailChange} value={email} />

                        </div>

                        <div className='P-Div-1'>

                            <label htmlFor="">Password</label>

                            <input type="password" placeholder='Password (min 6 character)' onChange={e => setPassword(e.target.value)} />
                            <input type="password" placeholder='Confirm Password' onChange={e => setConPassword(e.target.value)} />


                        </div>


                    </div>

                    <div className='Save-Btn-Div'>
                        <button className='SP-Btn SP-Link' type="submit" > Save Profile </button>
                    </div>
                </form>
                <Link className='Refer-Link' to='/ReferUser'>


                    <div className='Refer-div'>
                        <p className="New-div">Refer New User</p>
                        <p className='Pre-Tym-div'>All User can predict 1 Time. however<br />
                            you can get up to <b>10 Predictions chance <br />if you refer new user</b>
                        </p>
                    </div>

                </Link>

                <Advertisements />
                {/* <Adds></Adds> */}

            </div>

        )
    } else {
        return (<div className="edit-profileloader">
            <Box className="box-skele">
                <Skeleton animation="wave" height={90} />
                <Skeleton variant="circular" className="skelebox-circle my-2" width={80} height={80} />
                <Skeleton animation="wave" height={60} className="mb-2" />
                <Skeleton animation='wave' height={30} width={100} />
                <Skeleton animation='wave' height={60} />
                <Skeleton animation='wave' height={30} width={100} />
                <Skeleton animation='wave' height={60} />
                <Skeleton animation='wave' height={30} width={100} />
                <Skeleton animation='wave' height={60} />
                <Skeleton animation='wave' height={60} />
                <Skeleton animation='wave' height={80} className="my-2" />
                <Skeleton animation='wave' height={200} />
            </Box>

        </div>)
    }
}


export default EditProfile;