import React from 'react'
import './AllPredictStatistic.css';
import Header3 from '../../subComponent/Header3/Header3';
import Ger from '../../../Image/de.svg';
import it from '../../../Image/it.svg';
import Br from '../../../Image/br.svg';
import AuthUser from '../../AuthUser/AuthUser';
import { useState, useEffect } from 'react';
const AllPredictStatistic = () => {
    const { http } = AuthUser();
    // All user Prediction
    const [dataResult, setDataResult] = useState();
    const [data, getData] = useState([]);
    const numDescending = [...data].sort((a, b) => b.score - a.score);
    const fetchData = () => {
        http.post('championscore')
            .then(response => {

                if(response.data.status == "warning"){
                    setDataResult("error");
                }else{
                    setDataResult("success");
                    getData(response.data);
                }


            })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        fetchData()
    }, [])

    // var sortItem = items.score

    


    return (

        <div className='Main-AlLPredictStatistic-Container'>

            <div>

                <Header3 />

            </div>

            <div className='Table-All'>

                <table className='Country-Tb table table table-striped' cellSpacing="0" cellPadding="0px">
                    <thead>
                    <tr className='Tr-heading'>
                        
                        <th  className='APS-heading'>Team</th>
                        <th className='APS-heading'>% of User Predictions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataResult == "success"?
                    numDescending.map((item, i) => (

                        <tr className='Country-List' key={i}>
                            <td className='d-flex justify-content-center align-items-center dsadervd'>
                                <img className='Flag-Icon mr-2 ml-4 ' src={item.flag} /> {item.team}
                                </td>
                            <td className='text-end bold pr-4 '> {item.score}%</td>
                        </tr>
                    )):(<></>)}
                </tbody>
                   
                </table>

            </div>


        </div>

    )
}

export default AllPredictStatistic