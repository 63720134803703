import React from 'react'
import { IoChevronBack } from 'react-icons/io5'
import { useNavigate } from 'react-router'
import './StickyHomeButton.css'

const StickyHomeButton = () => {
    const navigate = useNavigate()
    const goBackHandler = () => {
        navigate('/')
    }
  return (
      <div onClick={goBackHandler} className="goBackButton">
          <IoChevronBack />
    </div>
  )
}

export default StickyHomeButton
