import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import './Drawer.css';
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import userImg from '../../../Image/user.png';
import Home from '../../../Image/home.svg';
import { CgSearchFound } from 'react-icons/cg'
import { AiOutlineTrophy } from 'react-icons/ai'
import { TbFlag2 } from 'react-icons/tb'
import { BiGitCompare } from 'react-icons/bi'
import { BiUserCircle } from 'react-icons/bi'
import { BiLogIn } from 'react-icons/bi'
import AuthUser from '../../AuthUser/AuthUser';
import {BiUserCheck} from 'react-icons/bi'


export default function MyDrawer() {
    const {user,logout,token} = AuthUser();
    const userLogout = () =>{
        if(token != undefined){
            logout();
        }
    }
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box className="sidebardrawer"
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            <List>


                <div className='Main-Drawer'>


                    <div className='Drawer-Name-Div'>
                        <div className="drawer-profile">
                        <img src={user.profile == '' ? userImg : user.profile } />
                        </div>
                        <div>
                            <p className='Drawer-Name-Text'>{user.username} <Link to="/EditProfile" className="Edit-icon"></Link></p>
                            <p className='Drawer-Email'>{user.email}</p>
                        </div>

                    </div>
                </div>

            </List>


            <List>



                <div className='Link-Btn-All'>
                    <Link className='Link-Home' to='/Home'> <img src={Home} img='alt' className='Home-icon' /> Home</Link>
                    <Link className='Link-Home' to='/AllPredictUser'> <CgSearchFound className='Home-icon' /> All User Prediction</Link>
                    <Link className='Link-Home' to='/AllPredictStatistic'> <AiOutlineTrophy className='Home-icon' /> Champion Prediction Statistics</Link>
                    <Link className='Link-Home' to='/MyPredictHistory'> <TbFlag2 className='Home-icon' /> My Prediction History</Link>
                    <Link className='Link-Home' to='/ReferUser'> <BiGitCompare className='Home-icon' />Referral</Link>
                    <Link className='Link-Home' to='/SaveProfile'> <BiUserCircle className='Home-icon' /> Profile</Link>
                    {user.verified == "verified"?(
                        <></>
                    ):(
                        <>
                          <Link className='Link-Home' to='/VerifyAccount'> <BiUserCheck className='Home-icon' /> VerifyAccount</Link>
                          </>
                    )
                    }
                  
                    <div className='Link-Divider'></div>
                    <button className='Link-Home-1 bg-white border-none' onClick={userLogout}><BiLogIn className='Home-icon' /> Logout</button>
                </div>
            </List>

        </Box>
    );

    return (
        <div className="sidebardrawer">
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button className='Drawer-Btn' onClick={toggleDrawer(anchor, true)}>{<MenuIcon />}</Button>
                    <Drawer
                      transitionDuration={{ enter: 400, exit: 400 }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
