import * as FormData from 'form-data';
import React, { useState } from 'react';
import './Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faG } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../AuthUser/AuthUser';
import SocialAuth from '../../Config/authMethod'
import {FiEyeOff} from 'react-icons/fi';
import {FiEye} from 'react-icons/fi';
import Fade from 'react-reveal/Fade'; // Importing Fade effect 
import { useParams } from 'react-router-dom';
import StickyHomeButton from '../../subComponent/StickyHomeButton/StickyHomeButton';
const Login = () => {

    const navigate = useNavigate()
    const { getToken } = AuthUser();
    const {signInWithGoogle} = SocialAuth();
    if (getToken()) {
        navigate('/Home')
    }

    const [changePassword, setChangePassword] = useState(true);
    const changeIcon = changePassword === true ? false : true;
    const { http, setToken, alertMessage } = AuthUser();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState(null);
    async function submitForm(e) {
        e.preventDefault();
        if(email != '' && password !=''){
        let data = new FormData()
        data.append('email', email)
        data.append('password', password) 
        http.post('login', data)
            .then(function (res) {
                console.log(res.data.status);
                if (res.data.status == "success") {
                    setTimeout(() => {
                        setToken(res.data.user, res.data.access_token);
                    }, 1500);
                }
                setMsg(null);
                setMsg(alertMessage(res.data.status, res.data.msg));
            })
            .catch(err => console.log(err))
        } else{
            const status = "warning";
            const msg = "Enter Your Email and Password";
            setMsg(null);
            setMsg(alertMessage(status,msg));
        }
    }

    // const  [googleprovider, facebookprovider, appleprovider ] = authMethod()
    

   

    return (
        <Fade>
            <div className='Main-Login-container'>
                <StickyHomeButton />
            <div className=' Main-Heading-Div'>
                <p className='SignUp-heading' onClick={()=>{navigate('/')}}>Top3 Prediction</p>
                <p className='create-heading'>Login to your Account</p>
            </div>
            <div className='Log-Btn-Div'>
                <button className='App-btn-1' onClick={signInWithGoogle} ><FontAwesomeIcon icon={faG} className='G-Btn'  /> Login with Google</button>
               
              
            </div>
            <div id="alert" className='p-3'>
                {msg ? msg : ""}
            </div>
            <form action="" onSubmit={submitForm} method="post">
                <div className='EP-Div'>
                    <div className='E-Div'>
                        <label>Email Address</label>
                        <input type="text" name='email' placeholder='youremail@yourdomain' onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className='P-Div'>
                        <label htmlFor="">Password</label>
                        <input type={changePassword ? "password" : "text"} placeholder='Password (min 6 Character)' name='password' onChange={e => setPassword(e.target.value)} />
                        <span className="icon-eye"
                            onClick={() => { setChangePassword(changeIcon); }}
                        >
                            {changeIcon ? <FiEyeOff /> : <FiEye />}
                        </span>
                        <Link className='For-Btn' to='/ForgotPassword'>  Forgot Password?</Link>
                    </div>
                </div>
                <div className='LWE-Div'>
                    <button className='Link-LWH' type="submit">Login with Email </button>
                </div>
                <div className='sign-Up-div'>
                    <p>or</p>
                    <p>Didnt have an account? <Link to='/SignUp' className='sign-up-btn'>Sign Up</Link></p>
                </div>
            </form>
        </div>
        </Fade>
    )
}
export default Login