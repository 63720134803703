import React from 'react';
import './WelScreen.css'
import { Link } from 'react-router-dom';
import AuthUser from '../../AuthUser/AuthUser';
import { useNavigate } from 'react-router-dom'; 
import Fade from 'react-reveal/Fade'; // Importing Fade effect
const WelScreen = () => {
    const navigate = useNavigate()
    const {getToken} = AuthUser();
    if(getToken()){
        navigate('/Home')
    }
    return (
        <Fade>
        <div className='Wel-Main-Container'> 
            <div className='Wel-Back-1'> 
                <div className='Wel-Back-2'> 
                    <div className='Main-heading-Div'>
                        <p className='Main-Heading' onClick={()=>{navigate('/')}}>Top3 Prediction</p> 
                    </div>


                    <div className='Wel-Content-div'>


                        <div className='Wel-Text'>

                            <p>Predict the Champion and Runner up of World Cup 2022 and Win The Prize</p>

                        </div>

                        <div className='Login-Btn-div'>

                            <Link to="/Home" className='GS-Btn'>Get Started</Link>
                            <Link to="/Login" className='Login-Btn'>Login</Link>
                        </div>

                        <div className='sign-Up-div'>
                            <p>or</p>

                            <p>Didnt have an account? <Link to='/SignUp' className='sign-up-btn'>Sign Up</Link></p>
                        </div>

                    </div>
                </div>


            </div>


        </div>
        </Fade>
    )
}

export default WelScreen;